/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CalendarEntryDto } from '../model/calendarEntryDto';
import { CopyDayRequestDto } from '../model/copyDayRequestDto';
import { ExpenseOptions } from '../model/expenseOptions';
import { TimeBookingCreateDto } from '../model/timeBookingCreateDto';
import { TimeBookingResult } from '../model/timeBookingResult';
import { TimeBookingStateDto } from '../model/timeBookingStateDto';
import { TimebookingDetailDto } from '../model/timebookingDetailDto';
import { TimebookingDetailDtoSearchResponse } from '../model/timebookingDetailDtoSearchResponse';
import { TimebookingSaveDto } from '../model/timebookingSaveDto';
import { TimebookingSearchRequestDto } from '../model/timebookingSearchRequestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TimeBookingService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyDayForEmployee(body?: CopyDayRequestDto, observe?: 'body', reportProgress?: boolean): Promise<Array<TimebookingDetailDto>>;
    public copyDayForEmployee(body?: CopyDayRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<TimebookingDetailDto>>>;
    public copyDayForEmployee(body?: CopyDayRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<TimebookingDetailDto>>>;
    public copyDayForEmployee(body?: CopyDayRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/timebooking/copy/day`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBooking(body?: TimeBookingCreateDto, observe?: 'body', reportProgress?: boolean): Promise<TimebookingDetailDto>;
    public createBooking(body?: TimeBookingCreateDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<TimebookingDetailDto>>;
    public createBooking(body?: TimeBookingCreateDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<TimebookingDetailDto>>;
    public createBooking(body?: TimeBookingCreateDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/timebooking/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBooking(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public deleteBooking(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public deleteBooking(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public deleteBooking(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBooking.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/timebooking/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingDetail(id: string, observe?: 'body', reportProgress?: boolean): Promise<TimebookingDetailDto>;
    public getBookingDetail(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<TimebookingDetailDto>>;
    public getBookingDetail(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<TimebookingDetailDto>>;
    public getBookingDetail(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBookingDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/timebooking/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingState(id: string, observe?: 'body', reportProgress?: boolean): Promise<TimeBookingStateDto>;
    public getBookingState(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<TimeBookingStateDto>>;
    public getBookingState(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<TimeBookingStateDto>>;
    public getBookingState(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBookingState.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/timebooking/${encodeURIComponent(String(id))}/state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param from 
     * @param to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCalendarFor(from?: Date, to?: Date, observe?: 'body', reportProgress?: boolean): Promise<Array<CalendarEntryDto>>;
    public getCalendarFor(from?: Date, to?: Date, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<CalendarEntryDto>>>;
    public getCalendarFor(from?: Date, to?: Date, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<CalendarEntryDto>>>;
    public getCalendarFor(from?: Date, to?: Date, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/timebooking/calendar`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGuaranteeServiceCodes(observe?: 'body', reportProgress?: boolean): Promise<Array<number>>;
    public getGuaranteeServiceCodes(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<number>>>;
    public getGuaranteeServiceCodes(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<number>>>;
    public getGuaranteeServiceCodes(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/timebooking/guaranteeServiceCodes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLockDate(observe?: 'body', reportProgress?: boolean): Promise<Date>;
    public getLockDate(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Date>>;
    public getLockDate(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Date>>;
    public getLockDate(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/timebooking/lockData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param date 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hasAnyExpenseOptionsBookingOn(date?: Date, options?: Array<ExpenseOptions>, observe?: 'body', reportProgress?: boolean): Promise<boolean>;
    public hasAnyExpenseOptionsBookingOn(date?: Date, options?: Array<ExpenseOptions>, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<boolean>>;
    public hasAnyExpenseOptionsBookingOn(date?: Date, options?: Array<ExpenseOptions>, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<boolean>>;
    public hasAnyExpenseOptionsBookingOn(date?: Date, options?: Array<ExpenseOptions>, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('Date', <any>date.toISOString());
        }
        if (options) {
            options.forEach((element) => {
                queryParameters = queryParameters.append('Options', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/timebooking/hasAnyExpenseOptionsBookingOn`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param from 
     * @param to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listFor(from?: Date, to?: Date, observe?: 'body', reportProgress?: boolean): Promise<TimeBookingResult>;
    public listFor(from?: Date, to?: Date, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<TimeBookingResult>>;
    public listFor(from?: Date, to?: Date, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<TimeBookingResult>>;
    public listFor(from?: Date, to?: Date, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/timebooking/list`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retransmitBooking(id: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public retransmitBooking(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public retransmitBooking(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public retransmitBooking(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling retransmitBooking.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/timebooking/${encodeURIComponent(String(id))}/retransmit`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveBooking(body?: TimebookingSaveDto, observe?: 'body', reportProgress?: boolean): Promise<TimebookingDetailDto>;
    public saveBooking(body?: TimebookingSaveDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<TimebookingDetailDto>>;
    public saveBooking(body?: TimebookingSaveDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<TimebookingDetailDto>>;
    public saveBooking(body?: TimebookingSaveDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.put(`${this.basePath}/api/timebooking`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(body?: TimebookingSearchRequestDto, observe?: 'body', reportProgress?: boolean): Promise<TimebookingDetailDtoSearchResponse>;
    public search(body?: TimebookingSearchRequestDto, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<TimebookingDetailDtoSearchResponse>>;
    public search(body?: TimebookingSearchRequestDto, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<TimebookingDetailDtoSearchResponse>>;
    public search(body?: TimebookingSearchRequestDto, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/timebooking/search`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
